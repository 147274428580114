import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";

const projectId = "98ba3392910d4719069959863974187e";

const BEP20 = {
  chainId: 56,
  name: "Binance Smart Chain",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://bsc-dataseed.binance.org",
};

const NOWChain = {
  chainId: 2014,
  name: "NOW Chain",
  currency: "NOW",
  explorerUrl: "https://nowscan.io",
  rpcUrl: "https://rpc-testnet.nowscan.io",
};

const metadata = {
  name: "NOW Swap",
  description: "NOW Swap",
  url: "https://nowblockchain.io/",
  icons: [
    "https://nowblockchain.io/static/media/iconFooter.4abbaabff943c1e11dcf.png",
  ],
};

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  rpcUrl: "https://bsc-dataseed.binance.org",
  defaultChainId: 56,
});

createWeb3Modal({
  ethersConfig,
  chains: [BEP20, NOWChain],
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
  //   themeMode: "light",
  //   themeVariables: {
  //     "--w3m-accent-color": "#F5841F",
  //     "--w3m-accent-fill-color": "#F5841F",
  //     "--w3m-background-color": "#F5841F",
  //   } ,
});

export function Web3Modal({ children }) {
  return children;
}
