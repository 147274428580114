import { Button } from "@mui/material";

export default function ButtonCustom({
  children,
  onClick,
  variant = "contained",
  type,
  sx,
  disabled,
  ...field
}) {
  switch (type) {
    case "white":
      return (
        <Button
          disabled={disabled}
          onClick={onClick ? onClick : () => {}}
          variant={variant}
          {...field}
          sx={{
            color: "#fff",
            padding: "10px 15px",
            background: "none",
            fontWeight: "bold",
            border: "1px rgba(255, 255, 255, 0.5) solid", 
            borderRadius: "10px",
            transition: "all 0.5s ease",
            ":hover": {
              background:
                "linear-gradient(180deg, #E80274 0%, #7D0D6A 100%),linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))",
              border: "1px transparent solid", 
            },
            ...sx,
          }}
        >
          {children}
        </Button>
      );

    default:
      return (
        <Button
          disabled={disabled}
          onClick={onClick ? onClick : () => {}}
          variant={variant}
          {...field}
          sx={{
            color: "white",
            padding: "10px 15px",
            borderRadius: "10px",
            background:
                "linear-gradient(180deg, #E80274 0%, #7D0D6A 100%),linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))",
            fontWeight: "bold",
            transition: "all 0.5s ease",
            ":hover": {
              background: "none",
              color: "#fff",
              border: "1px rgba(255, 255, 255, 0.5) solid",
            },
          }}
        >
          {children}
        </Button>
      );
  }
}
