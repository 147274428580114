import { callAPI } from "api";
import { METHOD } from "constants/config";
import { endpointAPI } from "constants/endPointAPI";
import { MESSAGE } from "constants/message";
import {
  checkFaucetFail,
  checkFaucetStart,
  checkFaucetSuccess,
  handleFaucetFail,
  handleFaucetStart,
  handleFaucetSuccess,
} from "redux/slice/faucetSlice";
import { toastMessage } from "utils/toastMessage";

// CHECK FAUCET
export async function checkFaucet(dispatch) {
  dispatch(checkFaucetStart());
  const res = await callAPI(endpointAPI.faucet.check, METHOD.GET);
  if (res.isOk) {
    return dispatch(checkFaucetSuccess(res?.data?.data));
  }
  return dispatch(checkFaucetFail(res.data.errors));
}
// HANDLE FAUCET
export async function handleFaucet(dispatch) {
  dispatch(handleFaucetStart());
  const res = await callAPI(endpointAPI.faucet.handle, METHOD.POST);
  if (res.isOk) {
    toastMessage(res?.data?.message, MESSAGE.SUCCESS);
    checkFaucet(dispatch);
    return dispatch(handleFaucetSuccess(res?.data?.data));
  }
  toastMessage(res?.data?.message, MESSAGE.ERROR);
  return dispatch(handleFaucetFail(res.data.errors));
}
