import { styled } from "styled-components";

export const Wrapper = styled.div``;

export const ChartStyles = {
  Main: styled.div`
    padding: 20px;
    background: rgba(217, 217, 217, 0.2);
    border-radius: 20px;
    flex: 60%;
  `,
  Pair: styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
  `,
  Icon: styled.img`
    width: 40px;
    height: 40px;
  `,
  PairName: styled.p`
    font-weight: bold;
    font-size: 20px;
  `,
  Price: styled.div`
    > b {
      font-size: 40px;
      color: #e80274;
    }
    > span {
      color: green;
    }
  `,
  Date: styled.p`
    font-style: italic;
    margin: 0;
    font-size: 13px;
  `,
  ListOption: styled.div`
    display: flex;
    background: #f5f5dc59;
    border-radius: 30px;
    width: fit-content;
  `,
  Option: styled.p`
    margin: 0;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    padding: 5px 10px;
    ${(props) =>
      props.isActive === true &&
      `
    background: #e90375d1;
    border-radius: 30px;
    `}
  `,
  Info: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 540px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  `,
};
