/* eslint-disable react-hooks/exhaustive-deps */
import logoHeader from "assets/img/logo-header.png";
import iconFB from "assets/svg/icon-fb.svg";
import iconIns from "assets/svg/icon-ins.svg";
import iconTele from "assets/svg/icon-tele.svg";
import iconTw from "assets/svg/icon-tw.svg";
import iconYoutube from "assets/svg/icon-youtube.svg";
import ButtonOpenClose from "components/Atoms/ButtonOpenClose";
import { PAGE } from "constants/page";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setUserInfo } from "redux/slice/userSlice";
import { FooterStyles } from "../Footer/styles";
import {
  EndHeader,
  HeaderMobile,
  HeaderStyles,
  LineStyles,
  Links,
  Menu,
  Wrapper,
} from "./styles";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { handleSignin } from "services/auth";
const menu = [
  // {
  //   text: "HOME",
  //   href: PAGE.home,
  // },
  {
    text: "SWAP",
    href: PAGE.swap,
  },
  {
    text: "FAUCET",
    href: PAGE.faucet,
  },
];

export default function Header() {
  // HOOK
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { address, isConnected } = useWeb3ModalAccount();

  // STATE
  const [open, setOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    dispatch(setUserInfo(user));
  }, []);

  useEffect(() => {
    if (isConnected && address) {
      if (!user) {
        handleSignin(dispatch, {
          wallet: address,
        });
      }
    } else {
      localStorage.removeItem("user");
    }
  }, [address, isConnected]);

  return (
    <Wrapper>
      <div className="container" style={{ height: "100%" }}>
        <HeaderStyles.Main>
          <HeaderStyles.Logo
            src={logoHeader}
            onClick={() => {
              navigate(PAGE.dashboard);
            }}
          />

          <HeaderStyles.ListMenu>
            {menu.map((item, key) => (
              <HeaderStyles.Menu
                isActive={location.pathname === item.href}
                onClick={() => {
                  navigate(item.href);
                }}
                key={key}
              >
                {item.text}
              </HeaderStyles.Menu>
            ))}
          </HeaderStyles.ListMenu>

          <HeaderStyles.ButtonConnect>
            <w3m-button />
          </HeaderStyles.ButtonConnect>

          <Menu.Mobile>
            <ButtonOpenClose open={open} setOpen={setOpen} />
          </Menu.Mobile>
        </HeaderStyles.Main>
        {open && (
          <HeaderMobile.Main data-aos="fade-left">
            {menu.map((item, key) => {
              return (
                <Menu.Item
                  key={key}
                  isActive={location.pathname === item.href}
                  onClick={() => {
                    setOpen(false);
                    navigate(item.href);
                  }}
                >
                  {item.text}
                </Menu.Item>
              );
            })}
            <HeaderMobile.ButtonConnectMB>
              <w3m-button />
            </HeaderMobile.ButtonConnectMB>

            <LineStyles />
            <EndHeader.Main>
              <EndHeader.Social>
                <Links href="https://t.me/nowblockchain" target="_blank">
                  <FooterStyles.Icon src={iconTele} />
                </Links>
                <Links
                  href="https://twitter.com/nowblockchainx?s=21&t=_sFF4qF8bFhkNCmSKnt2Kg"
                  target="_blank"
                >
                  <FooterStyles.Icon src={iconTw} />
                </Links>
                <Links
                  href="https://www.youtube.com/@NOWBlockchain"
                  target="_blank"
                >
                  <FooterStyles.Icon src={iconYoutube} />
                </Links>
                <Links
                  href="https://www.facebook.com/NOWblockchain?mibextid=LQQJ4d"
                  target="_blank"
                >
                  <FooterStyles.Icon src={iconFB} />
                </Links>
                <Links href="https://www.instagram.com/" target="_blank">
                  <FooterStyles.Icon src={iconIns} />
                </Links>
              </EndHeader.Social>
            </EndHeader.Main>
          </HeaderMobile.Main>
        )}
      </div>
    </Wrapper>
  );
}
