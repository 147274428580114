
import { ethers, parseUnits } from "ethers";
import { toastMessage } from "./toastMessage";
import { MESSAGE } from "constants/message";

// TRANSFER TOKEN
export const transferToken = async (walletProvider, contractAddress, ABI, to, amount) => {
    try {
        const ethersProvider = new ethers.BrowserProvider(walletProvider)
        const signer = await ethersProvider.getSigner()
        const contract = new ethers.Contract(contractAddress, ABI, signer)
        const res = await contract.transfer(to, parseUnits(amount));
        return res?.hash
    } catch (error) {
        const message = error.shortMessage ? error.shortMessage : error.message
        toastMessage(`Transaction failed: ${message}`, MESSAGE.ERROR);
        throw new Error('Transaction failed: ' + error.message);
    }
}
// TRANSFER BNB
export const transferBNB = async (walletProvider, to, amount) => {
    try {
        const ethersProvider = new ethers.BrowserProvider(walletProvider)
        const signer = await ethersProvider.getSigner()
        const valueInEther = parseUnits(amount);
        const tx = await signer.sendTransaction({
            to,
            value: valueInEther,
        });
        return tx?.hash;

    } catch (error) {
        const message = error.shortMessage ? error.shortMessage : error.message
        toastMessage(`Transaction failed: ${message}`, MESSAGE.ERROR);
        throw new Error('Transaction failed: ' + error.message);
    }
}

// SWITCH NETWORK
export const switchNetwork = async ({ walletProvider, chainID, chainName, rpcUrl, symbol, decimals, explorerUrl }) => {
    const chainIdHex = '0x' + chainID.toString(16);
    try {
        await walletProvider.send('wallet_switchEthereumChain', [{ chainId: chainIdHex }]);
    } catch (switchError) {
        try {
            await walletProvider.send('wallet_addEthereumChain', [
                {
                    chainId: chainIdHex,
                    chainName: chainName,
                    rpcUrls: [rpcUrl],
                    nativeCurrency: {
                        name: chainName,
                        symbol: symbol,
                        decimals: decimals,
                    },
                    blockExplorerUrls: [explorerUrl],
                },
            ]);
        } catch (addError) {
            console.error(addError);
        }
    }
}