/* eslint-disable react-hooks/exhaustive-deps */
import ButtonCustom from "components/Atoms/Button";
import { FormStyles } from "./styles";
import InputBase from "components/Atoms/InputBase";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { masterSelector } from "redux/slice/masterSlice";
import { userSelector } from "redux/slice/userSlice";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { checkFaucet, handleFaucet } from "services/faucet";
import { faucetSelector } from "redux/slice/faucetSlice";
import { CircularProgress } from "@mui/material";
import videoFaucet from 'assets/gift/faucet.gif'


export default function FromSwap() {
  const dispatch = useDispatch();
  const { faucetConfig } = useSelector(masterSelector);
  const { faucetInfo, isProcessing } = useSelector(faucetSelector);
  const { userAuth } = useSelector(userSelector);

  const { address, isConnected } = useWeb3ModalAccount();

  useEffect(() => {
    if (address && userAuth) {
      checkFaucet(dispatch);
    }
  }, [address, userAuth]);

  return (
    <FormStyles.Main>
      <p
        style={{
          fontSize: "35px",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "0",
        }}
      >
        NOW <span style={{ color: "#e80274" }}>FAUCET</span>{" "}
      </p>
      <p style={{ textAlign: "center" }}>
        Obtain NOW coin for seamless and
        confident development.
      </p>
      <FormStyles.Flex>
        <div
          style={{
            flex: 1,
          }}
        >
          <div
            style={{
              width: "fit-content",
              textAlign: "center",
              margin: "auto",
            }}
          >
            <img src={videoFaucet} alt="" />
          </div>
        </div>

        <div style={{ flex: 1 }}>
          Your wallet
          <InputBase
            disabled
            placeholder={address || "Please connect your wallet"}
            type="text"
            style={{ fontSize: "18px" }}
          />
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {!faucetInfo?.status && <p style={{ color: "#e80274" }}>Each wallet can only be faucet once!</p>}
            {isConnected ? (
              <>

                <ButtonCustom
                  disabled={!faucetInfo?.status}
                  onClick={() => {
                    handleFaucet(dispatch);
                  }}
                >
                  {isProcessing ? (
                    <CircularProgress color="secondary" size="1.5rem" />
                  ) : (
                    `FAUCET ${faucetConfig?.faucet_amount} NOW`
                  )}
                </ButtonCustom>

              </>
            ) : (
              <w3m-button />
            )}
          </div>
        </div>
      </FormStyles.Flex>
    </FormStyles.Main>
  );
}
