export function formatNumber(amount) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 18,
  });

  return formatter.format(amount);
}


export function formatDecimal18(amount) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 18,
  });

  return formatter.format(amount).replace(/,/g, "");
}