import { callAPI } from "api";
import { METHOD } from "constants/config";
import { endpointAPI } from "constants/endPointAPI";
import {
  getCoinInfoFail,
  getCoinInfoStart,
  getCoinInfoSuccess,
  getDataChartFail,
  getDataChartStart,
  getDataChartSuccess,
  getFaucetConfigFail,
  getFaucetConfigStart,
  getFaucetConfigSuccess,
  getListCoinStart,
  getListCoinSuccess,
  getReceiveAddressFail,
  getReceiveAddressStart,
  getReceiveAddressSuccess,
} from "redux/slice/masterSlice";

// GET COIN INFO
export async function getCoinInfo(dispatch, data) {
  dispatch(getCoinInfoStart());
  const res = await callAPI(endpointAPI.master.coinInfo, METHOD.GET, data);
  if (res.isOk) {
    return dispatch(getCoinInfoSuccess(res?.data?.data));
  }
  return dispatch(getCoinInfoFail(res.data.errors));
}

export async function getListCoin(dispatch, data) {
  dispatch(getListCoinStart());
  const res = await callAPI(endpointAPI.master.listCoin, METHOD.GET, data);
  if (res.isOk) {
    return dispatch(getListCoinSuccess(res?.data?.data));
  }
  return dispatch(getCoinInfoFail(res.data.errors));
}

// GET PRICE NOW
export async function getDataChart(dispatch, data) {
  dispatch(getDataChartStart());
  const res = await callAPI(endpointAPI.master.dataChart, METHOD.GET, data);
  if (res.isOk) {
    return dispatch(getDataChartSuccess(res?.data?.data));
  }
  return dispatch(getDataChartFail(res.data.errors));
}

// GET RECEIVE ADDRESS
export async function getReceiveAddress(dispatch) {
  dispatch(getReceiveAddressStart());
  const res = await callAPI(endpointAPI.master.receiveAddress, METHOD.GET, {});
  if (res.isOk) {
    return dispatch(getReceiveAddressSuccess(res?.data?.data));
  }
  return dispatch(getReceiveAddressFail(res.data.errors));
}

// GET FAUCET CONFIG
export async function getFaucetConfig(dispatch) {
  dispatch(getFaucetConfigStart());
  const res = await callAPI(endpointAPI.master.faucetConfig, METHOD.GET, {});
  if (res.isOk) {
    return dispatch(getFaucetConfigSuccess(res?.data?.data));
  }
  return dispatch(getFaucetConfigFail(res.data.errors));
}
