import iconTele from "assets/svg/icon-tele.svg";
import iconTw from "assets/svg/icon-tw.svg";
import iconYoutube from "assets/svg/icon-youtube.svg";
import iconFB from "assets/svg/icon-fb.svg";
import iconIns from "assets/svg/icon-ins.svg";

export const socials = [
  {
    icon: iconTele,
    href: "https://t.me/nowblockchain",
  },

  {
    icon: iconTw,
    href: "https://twitter.com/nowblockchainx?s=21&t=_sFF4qF8bFhkNCmSKnt2Kg",
  },
  {
    icon: iconYoutube,
    href: "https://www.youtube.com/@NOWBlockchain",
  },
  {
    icon: iconFB,
    href: "https://www.facebook.com/NOWblockchain?mibextid=LQQJ4d",
  },
  {
    icon: iconIns,
    href: "",
  },
];
