export const TIME_OUT = 30000;

export const METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const SETTING = {
  // OFFICIAL
  DOMAIN_API: "https://apizswap.nowblockchain.io",
  DOMAIN_FE: "https://swap.nowblockchain.io",

  // LOCAL
  // DOMAIN_API: "http://localhost:3211",
  // DOMAIN_FE: "https://nowswap.toearnnow.com",

  // TEST
  // DOMAIN_API: "https://api.nowswap.tocuna.com",
  // DOMAIN_FE: "https://nowswap.tocuna.com",

  CONTRACT_NOW: "0xC7999CF56E4C9B5EC8F5c1769c858FA62966b2D5",
  CONTRACT_USDT: "0x55d398326f99059fF775485246999027B3197955",
};
